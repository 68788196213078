(function (window, angular) {
    'use strict';

    var _tag = '[App]',
        _IS_DEBUG = true,
        _stateChangeStartPosition,
        _zuzModulesIncludes = [
            'ngSanitize',
            'ngMessages',
            'ngTouch',
            'ngAria',
            'ngCookies',
            'spGoogleMaps',
            'spApi',
            'spVerticalValueSlider',
            'spBubble',
            'spFilters',
            'spUnits',
            'spDialog',
            'spConstants',
            'spImageZoom',
            'ui.router',
            'ui.router.metatags',
            'spServices',
            'spPayments',
            'spTable',
            'spInlineError',
            'duScroll',
            'spInViewWatcher',
            'spHtmlComponentsRenderer',
            'spTemplates',
            'sp-autocomplete',
            'spDialogUrlManager',
            'spProductTags',
            'spVirtualRepeat',
            'spCaptcha',
            'sp360',
            'spHomePageComponents',
            'spItemsGrid',
            'spSeparateInputValue',
            'ngFileSaver',
        ];

    if (window.sp.env === 'production' || window.sp.env === 'release') {
        _IS_DEBUG = false;
    }

    window.app = angular.module('ZuZ', _zuzModulesIncludes)
        .constant('IS_DEBUG', _IS_DEBUG)
        .config([
            '$logProvider', '$httpProvider', '$stateProvider', '$urlRouterProvider', '$locationProvider',
            '$compileProvider', 'LoadingProvider', 'ApiProvider', 'SpDeliveryTimesServiceProvider',
            'spTableProvider', 'spInlineErrorProvider', 'SpDialogUrlManagerProvider',
            'PermanentFiltersProvider', 'PaymentsServiceProvider', 'FRONTEND_SERVICE_ID', 'IS_DEBUG',
            'SP_URL_DIALOG_QUERY_PARAMS', 'SP_URL_DIALOG_DATA_QUERY_PARAMS', 'URL_DIALOG_DATA_QUERY_PARAMS',
            'spAIAnalyticServiceProvider',
            function ( $logProvider, $httpProvider, $stateProvider, $urlRouterProvider, $locationProvider,
                       $compileProvider, loadingProvider, apiProvider, spDeliveryTimesServiceProvider,
                       spTableProvider, spInlineErrorProvider, SpDialogUrlManagerProvider,
                       PermanentFiltersProvider, PaymentsServiceProvider, FRONTEND_SERVICE_ID, IS_DEBUG,
                       SP_URL_DIALOG_QUERY_PARAMS, SP_URL_DIALOG_DATA_QUERY_PARAMS, URL_DIALOG_DATA_QUERY_PARAMS,
                       spAIAnalyticServiceProvider) {
                loadingProvider.loadingTemplate =
                    '<div class="loading-dialog-wrapper">' +
                    '<div class="overlay"></div>' +
                    '<div class="dialog animated">' +
                    '<div class="dialog-body">' +
                    '<img src="https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/loading.gif" alt="loading"/>' +
                    '</div>' +
                    '<span class="for-vertical-align"></span>' +
                    '</div>' +
                    '</div>';

                SpDialogUrlManagerProvider.closeDialog = ['Dialog', function(Dialog) {
                    return Dialog.hide();
                }];

                PermanentFiltersProvider.localStorage = ['LocalStorage', function(LocalStorage) {
                    return LocalStorage;
                }];


                spTableProvider.templates.actionsCheckboxes = {
                    th: '<th><sp-checkbox sp-model="tableCtrl.allActionsChecked" sp-change="tableCtrl.allActionsCheckedChanged()"></sp-checkbox></th>',
                    td: '<td><sp-checkbox ng-if="item.product" sp-model="item.actionsChecked" sp-change="tableCtrl.actionCheckboxChanged()" sp-disabled="item.actionsDisabled || item.quantity <= 0" ng-class="{\'disabled\': item.actionsDisabled}"></sp-checkbox></td>'
                };

                spInlineErrorProvider.errorGenerator = function (error, $filter) {
                    if (!angular.isObject(error)) {
                        return error;
                    }

                    return $filter('translate')(error.$name + ': ' + Object.keys(error.$error)[0] + ' error');
                };

                //  Add $rootScope decorator- To debug all $emit And $broadcast calls events on the $rootScope.
                // $provide.decorator('$rootScope', function ($delegate) {
                //     var _emit = $delegate.$emit,
                //         _broadcast = $delegate.$broadcast;
                //
                //     $delegate.$emit = function () {
                //         //console.log("[$emit] " + arguments[0] + " (" + JSON.stringify(arguments, undefined, 2) + ")");
                //         console.log("[$emit] " + arguments[0] + " (" + JSON.stringify(arguments) + ")");
                //         return _emit.apply(this, arguments);
                //     };
                //
                //     $delegate.$broadcast = function () {
                //         //console.log("[$broadcast] " + arguments[0] + " (" + JSON.stringify(arguments, undefined, 2) + ")");
                //         console.log("[$broadcast] " + arguments[0] + " (" + JSON.stringify(arguments) + ")");
                //         return _broadcast.apply(this, arguments);
                //     };
                //
                //     return $delegate;
                // });

                // $provide.decorator('$rootScope', function($delegate){
                //   // adds to the constructor prototype to allow
                //   // use in isolate scopes
                //     var proto = $delegate.constructor.prototype;
                //     proto.subscribe = function(event, listener) {
                //         console.log("[subscribe-listener] " + arguments[0] + " (" + JSON.stringify(arguments, undefined, 2) + ")");
                //         var unsubscribe = $delegate.$on(event, listener);
                //         this.$on('$destroy', unsubscribe);
                //     };
                //     proto.publish = function(event, data) {
                //         console.log("[$emit-event] " + arguments[0] + " (" + JSON.stringify(arguments, undefined, 2) + ")");
                //         console.log("[$emit-date] " + arguments[0] + " (" + JSON.stringify(arguments, undefined, 2) + ")");
                //         $delegate.$emit(event, data);
                //     };
                //     return $delegate;
                // });

                apiProvider.httpMethodOverride = true;
                apiProvider.setParameters = ['$rootScope', '$log', 'httpOptions', 'Config', 'User', 'callback', function ($rootScope, $log, httpOptions, config, user, callback) {
                    function setParams() {
                        config.initPromise.then(function() {
                            httpOptions.headers = httpOptions.headers || {};
                            if (config.retailer.id) {
                                httpOptions.url = httpOptions.url.replace(/:rid/g, config.retailer.id);

                                var branch = config.branch || config.retailer.branches[0];
                                if (branch && branch.id) {
                                    httpOptions.url = httpOptions.url.replace(/:bid/g, branch.id);
                                }
                            }

                            if (user.session.userId) {
                                httpOptions.url = httpOptions.url.replace(/:uid/g, user.session.userId);
                                httpOptions.headers.Authorization = user.getAuthorizationToken()
                            }

                            httpOptions.headers.Pathname = window.location.pathname;
                            httpOptions.params.appId = FRONTEND_SERVICE_ID;

                            request();
                        });
                    }

                    function request() {
                        if (httpOptions.url.indexOf(':uid') !== -1) {
                            var listener = $rootScope.$on('login', function () {
                                listener();
                                setParams();
                            });
                            return;
                        }

                        if (httpOptions.url.indexOf(':') === -1) {
                            callback();
                        } else {
                            $log.error('httpOptions.url param not replace, ', httpOptions);
                        }
                    }

                    setParams();
                }];

                angular.filterCollection = function (items, func) {
                    if (func && angular.isFunction(func) && items && (angular.isArray(items) || angular.isElement(items) || angular.isObject(items))) {
                        if (angular.isElement(items)) {
                            items = angular.element(items);
                        }
                        var isArray = angular.isArray(items) || angular.isElement(items),
                            res = isArray ? [] : {};
                        angular.forEach(items, function (value, key) {
                            if (func(value, key)) {
                                if (isArray) {
                                    res.push(value);
                                } else {
                                    res[key] = value;
                                }
                            }
                        });
                        return res;
                    } else {
                        return items;
                    }
                };

                $httpProvider.useApplyAsync(true);

                $httpProvider.interceptors.push(['$q', '$injector', function ($q, $injector) {
                    return {
                        'responseError': function (response) {
                            // reload when no connection values are stuck in the cache
                            if (response.status <= 0 && response.config.cache) {
                                location.reload();
                            }

                            if (response.status === 401) {
                                // remove params of login as
                                var $location = $injector.get('$location');
                                $location.search('token', null);
                                $location.search('userId', null);
                                $location.search('branchId', null);

                                $injector.get('Util').clearStorage(response.data && response.data.error && response.data.error === 'Token not active' ? 'session' : null);
                                
                            }
                            return $q.reject(response);
                        }
                    };
                }]);

                //// decorate $exceptionHandler to call window.onerror
                // $provide.decorator('$exceptionHandler', ['$delegate', function ($delegate) {
                //     return function (exception, cause) {
                //
                //         // try to retrieve the source line and column
                //         var splitSource = [],
                //             firstLine = exception.stack && exception.stack.split('\n')[1]; // the first line is the exception message
                //
                //         if (firstLine) {
                //             var splitLine = firstLine.split('/'),
                //                 source = splitLine[splitLine.length - 1];
                //
                //             if (source) {
                //                 splitSource = source.split(':');  // e.g. 'at http://localhost:3000/frontend/services/analytics.js:72:17'
                //             }
                //         }
                //
                //         window.dispatchEvent(new (window.ErrorEvent || window.Event)('error', {
                //             error: exception,
                //             message: exception.message,
                //             filename: splitSource[0],
                //             lineno: splitSource[1],
                //             colno: splitSource[2]
                //         }));
                //
                //         $delegate(exception, cause);
                //     }
                // }]);

                var stateUrl = '?{domain},{version},{template},{token},{userId},{branchId},{inviteId},{promotionId},{retailerId},{hubChildrenSearchText}',
                    dialogParams = [];

                angular.forEach([SP_URL_DIALOG_QUERY_PARAMS, SP_URL_DIALOG_DATA_QUERY_PARAMS, URL_DIALOG_DATA_QUERY_PARAMS], function (paramsObject) {
                    angular.forEach(paramsObject, function (params) {
                        Array.prototype.push.apply(dialogParams, params);
                    });
                });
                if (dialogParams.length) {
                    stateUrl += ',{' + dialogParams.join('},{') + '}';
                }
                $stateProvider.state('app', {
                    abstract: true,
                    url: stateUrl,
                    views: {
                        header: {
                            controller: 'HeaderCtrl as headerCtrl',
                            templateUrl: 'template/views/header/index.html'
                        },
                        sidenav: {
                            controller: 'SideNavCtrl as sideNavCtrl',
                            templateUrl: 'template/views/sidenav/index.html'
                        },
                        footer: {
                            controller: 'FooterCtrl as footerCtrl',
                            templateUrl: 'template/views/footer/index.html'
                        }
                    }
                });

                $urlRouterProvider
                    // TODO temp redirect from the old path, remove that once there are no longer banners and links pointing to it
                    .when('/tags/:productTagId/products' + stateUrl, '/product-tags/:productTagId/products' + stateUrl)
                    .when('/retailer/contact-us', '/user-edit/contact-us')
                    .otherwise('/');

                //Add $logProvider.debugEnabled to print all $log only in debug mode
                $logProvider.debugEnabled(IS_DEBUG);

                $compileProvider.debugInfoEnabled(IS_DEBUG);

                var html5Options = {
                    enabled: true
                };
                if (window.sp.env === 'release' || window.sp.env === 'production') {
                    html5Options.requireBase = false;
                }
                $locationProvider.html5Mode(html5Options);

                spDeliveryTimesServiceProvider.days = 7;
                spDeliveryTimesServiceProvider.getLocals = ['Config', function (config) {
                    return {
                        timeZoneOffset: config.retailer.timeZoneOffset,
                        languageId: config.language.id
                    };
                }];

                PaymentsServiceProvider.getConfig = [
                    'Config', 'User', 'Cart', 'CREDIT_CARD_MODES',
                    function(Config, User, Cart, CREDIT_CARD_MODES) {
                        return Config.initPromise.then(function() {
                            var creditCardMode = Config.branch && Config.branch.settings &&
                                Config.branch.settings.creditCardMode || Config.retailer.settings.creditCardMode;

                            return {
                                retailerId: Config.retailer.id,
                                branchId: Config.branch && Config.branch.id,
                                creditCardWindowType: Config.branch && Config.branch.creditCardWindowType || Config.retailer.creditCardWindowType,
                                creditCardSupportsExternalPaymentFlow: Config.branch &&
                                    Config.branch.creditCardSupportsExternalPaymentFlow || Config.retailer.creditCardSupportsExternalPaymentFlow,
                                isRememberCreditCards: creditCardMode === CREDIT_CARD_MODES.REMEMBER_CARDS,
                                cartId: Cart.serverCartId,
                                userId: User.session.userId,
                                token: User.session.token,
                                languageId: Config.language.id
                            };
                        });
                    }
                ];

                spAIAnalyticServiceProvider.getLocals = ['Config', 'User', function (config, user) {
                    return {
                        userId: (user && user.session) ? user.session.userId : null,
                        username: (user && user.session) ? user.session.username : null,
                        languageId: config.retailer.languageId
                    };

                }];

                PaymentsServiceProvider.showIFrameDialog = [
                    'Dialog', 'Config', 'options', 'finishEventPromise', 'SP_PAYMENTS',
                    function(Dialog, Config, options, finishEventPromise, SP_PAYMENTS) {
                        finishEventPromise.then(function(close) {
                            if (close !== false) {
                                Dialog.hide();
                            }
                        });

                        return Dialog.show({
                            templateUrl: 'template/dialogs/payment-iframe/index.html',
                            ariaLabelledby: 'payment_iframe_title',
                            styleClass: 'payment-iframe-dialog',
                            controller: 'PaymentIFrameDialogCtrl as paymentIFrameCtrl',
                            locals: { options: options },
                            disableClosing: options.paymentMethodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT && !options.isAddCard
                        });
                    }
                ];

                PaymentsServiceProvider.showAddGiftCardDialog = [
                    'Dialog', 'Config', 'options', 'SP_PAYMENTS',
                    function(Dialog, Config, options, SP_PAYMENTS) {
                        return Dialog.show({
                            templateUrl: 'template/dialogs/inner-payment-dialog/index.html',
                            ariaLabelledby: 'inner_payment_dialog_title',
                            styleClass: 'inner-payment-dialog-dialog',
                            controller: 'InnerPaymentDialogCtrl as innerPaymentCtrl',
                            locals: { options: options }
                        });
                    }
                ];

                PaymentsServiceProvider.showErrorDialog = ['Dialog', 'error', function(Dialog, error) {
                    return Dialog.show({
                        template: '<h4>{{error | translate}}</h4>',
                        controller: ['$scope', function($scope) {
                            $scope.error = error;
                        }]
                    });
                }];
            }])
        .run([
            '$rootScope', '$location', '$document', '$state', '$stateParams', '$timeout', '$filter', 'Toast', 'Dialog', 'Config', 'User', 'Util',
            'LocalStorage', 'Api', 'spUnitsConfig', 'spFilters', 'MetaTags', 'spInlineError', 'PermanentFilters',
            'spGoogleMaps', 'SpProductTags', 'STATE_NAMES', 'SpRecipeService', 'HubService', 'spAIAnalyticService',
            'AdobeAnalytics',
            function ($rootScope, $location, $document, $state, $stateParams, $timeout, $filter, toast, dialog, config, user, util,
                      localStorageService, api, spUnitsConfig, spFilters, metaTags, spInlineError, PermanentFilters,
                      spGoogleMaps, SpProductTags, STATE_NAMES, SpRecipeService, HubService, spAIAnalyticService, AdobeAnalytics) {

                spAIAnalyticService.init();


                SpProductTags.setRetailerId(config.retailer.id);

                if ($location.search().retailerId) {
                    var searchRetailerId = Number($location.search().retailerId),
                        foundRetailer = config.retailers.filter(function (retailer) {
                            return retailer.id === searchRetailerId;
                        });
                    if (!config.multiRetailers || config.retailer.id === searchRetailerId || !foundRetailer || !foundRetailer.length) {
                        localStorageService.setItem('preferredRetailerId', searchRetailerId);
                        $location.search('retailerId', null);
                    } else {
                       return api.request({
                            method: 'PUT',
                            url: '/frontend/' + searchRetailerId
                        }).then(function () {
                            localStorageService.clear();
                            location.reload();
                        });
                    }
                }

                var langQueryName = 'lang';
                if ($location.search()[langQueryName] && config.languages[$location.search()[langQueryName]]) {
                    config.language = config.languages[$location.search()[langQueryName]];
                }
                var countries = config.retailer.settings.countriesIsoSearch ? JSON.parse(config.retailer.settings.countriesIsoSearch) : [];
                var countriesIso = [];
                angular.forEach(countries, function(country) {
                    countriesIso.push(country.isoCode);
                });

                spGoogleMaps.init(config.language.id, countriesIso);

                spUnitsConfig.setCurrency(config.retailer.currencySymbol);
                spUnitsConfig.setGroupByMassUnit(config.retailer.defaultWeightUnitNames, config.retailer.currencySymbol === '$' ? 'us' : 'eu');
                spUnitsConfig.setNormalizerOverrides({mass: config.retailer.displayedPriceMeasureUnit});
                spUnitsConfig.setNormalizerOverrides({volume: config.retailer.displayedPriceMeasureUnit});

                spFilters.unitNames.defaultNames = config.retailer.defaultWeightUnitNames;
                spFilters.name.language = config.language;
                spFilters.name.retailerLanguage = config.languages[config.retailer.culture];
                spFilters.retailer.isRegularPriceWithTax = config.retailer.isRegularPriceWithTax;
                spFilters.retailer.includeTaxInPrice = config.retailer.includeTaxInPrice;
                spFilters.retailer.currencySymbol = config.retailer.currencySymbol;
                spFilters.retailer.currencyMinorUnit = config.retailer.currencyMinorUnit;
                spFilters.retailer.priceRoundModeId = config.retailer.priceRoundModeId;

                var isFirstLoad = true;
                $rootScope.$on('config.language.change', function (event, value) {
                    if (!isFirstLoad && $location.search()[langQueryName]) {
                        $location.search(langQueryName, null);
                    }
                    isFirstLoad = false;
                    spFilters.name.language = value;
                    spUnitsConfig.setDefaultLanguage(value.culture);
                    spInlineError.direction = value.direction;
                });

                $rootScope.config = config;
                $rootScope.PermanentFilters = PermanentFilters;

                metaTags.UIRouterMetatags.defaultTitle = (config.retailer.metaTitle || config.retailer.title);
                metaTags.UIRouterMetatags.suffix = ' | ' + (config.retailer.metaTitle || config.retailer.title);
                metaTags.UIRouterMetatags.defaultDescription = config.retailer.metaDescription && config.retailer.metaDescription !== 'null' ?
                    config.retailer.metaDescription : config.retailer.title + ' - makes online grocery shopping easy. Shop for fresh food and meals without leaving the convenience of your home.';
                metaTags.UIRouterMetatags.defaultKeywords = config.retailer.keywords && config.retailer.keywords !== 'null' ?
                    config.retailer.keywords : 'delivery,delivered,grocery,groceries,supermarket,fresh,frozen,meat,fish,produce,store,food,home grocery delivery,home grocery shopping,grocery shopping from home,home grocery delivery service';
                metaTags.image = $filter('image')(config.retailer.appIcon || config.retailer.favIcon || config.retailer.logoUrl);
                $rootScope.MetaTags = metaTags;

                $rootScope.$on('$stateChangeSuccess', function() {
                    $rootScope.MetaTags.canonical = (config.retailer.domain && $location.$$path) ? 'https://' + config.retailer.domain + $location.$$path : '';
                });

                function setWindowSize() {
                    $rootScope.windowSize = $rootScope.windowSize || {};
                    $rootScope.windowSize.height = window.innerHeight || window.clientHeight;
                    $rootScope.windowSize.width = window.innerWidth || window.clientWidth;
                }

                setWindowSize();

                //to remove old local storage key ('zipCode') and put its value in the new key ('area')
                var zipCode = localStorageService.getItem('zipCode');
                if (zipCode) {
                    localStorageService.setItem('area', zipCode);
                    localStorageService.removeItem('zipCode');
                }

                var windowElement = angular.element(window);
                windowElement.bind('resize', function (event) {
                    setWindowSize();
                    $rootScope.$emit('resize', event);
                });

                // use interval instead of $watch, it seems to be lighter
                var prevBodyWidth;
                setInterval(function() {
                    var bodyWidth = document.body.clientWidth;
                    if (!prevBodyWidth) {
                        prevBodyWidth = bodyWidth;
                        return;
                    }

                    if (bodyWidth !== prevBodyWidth) {
                        $rootScope.$emit('resize', {target: document.body});
                    }
                    prevBodyWidth = bodyWidth;
                }, 200);
                /*$rootScope.$watch(function () {
                    return document.body.clientWidth;
                }, function() {
                    $rootScope.$emit('resize', {target: document.body});
                });*/

                windowElement.bind('message', function (event) {
                    if (event.data && event.data.name) {
                        $rootScope.$emit(event.data.name, event);
                    } else {
                        $rootScope.$emit('message', event);
                    }
                    $rootScope.$applyAsync();
                });

                //////////////  *********** visibility API *********** //////////
                function getLastUpdateTime() {
                    api.request({
                        method: 'GET',
                        url: '/v2/templates/'+ sp.frontendData.templateName + '/version'
                    }).then(function (resp) {
                        if (sp.frontendData.templateVersion != resp.version){
                            window.location.reload();
                        }
                    });
                }

                var vendorPrefix,
                    prevEvent,
                    visibleEventHandle;

                windowElement.on('blur', function () {
                    if (prevEvent === 'blur') return;
                    // $rootScope.$emit('windowBlur', event);
                    prevEvent = 'blur';
                });

                windowElement.on('focus', function (event) {
                    if (prevEvent === 'focus') return;
                    $rootScope.$emit('windowFocus', event);
                    prevEvent = 'focus';
                });

                //Control the focus element - scroll the page (if necessary) to the location of the element (for keyboard users)
                window.addEventListener('focusin', function (event) {
                    var header = document.querySelector('body > header'),
                        checkoutBottomBanner = document.querySelector('checkout-bottom-banner'),
                        eventTarget = event.target || event.srcElement || event.currentTarget,
                        currentElement = eventTarget,
                        offsetParent,
                        top = 0,
                        documentHeight = Math.max(/*body.scrollHeight,*/ window.innerHeight) - (checkoutBottomBanner && checkoutBottomBanner.offsetHeight || 0),
                        documentScrollElement = document.documentElement || document.body;

                    while (currentElement && currentElement !== documentScrollElement) {
                        if (offsetParent === currentElement) {
                            offsetParent = null;
                        }
                        if (!offsetParent) {
                            top += currentElement.offsetTop;
                            offsetParent = currentElement.offsetParent;
                        }
                        var overflow = util.getCurrentStyleProp(currentElement, 'overflow-y'),
                            position = util.getCurrentStyleProp(currentElement, 'position');
                        if (overflow === 'auto' || overflow === 'scroll' || position === 'fixed' || (eventTarget.offsetHeight > documentHeight / 2)) {
                            return;
                        }
                        currentElement = currentElement.parentElement || currentElement.parentNode;
                    }

                    var headerHeight = header && header.offsetHeight || 0;
                    if (top < (documentScrollElement.scrollTop + headerHeight)) {
                        window.scrollTo(0, top - headerHeight);
                    } else if ((top + eventTarget.offsetHeight) > (documentScrollElement.scrollTop + documentHeight)) {
                        window.scrollTo(0, top - (documentHeight - eventTarget.offsetHeight));
                    }
                    /*if ((top < (documentScrollElement.scrollTop + (header && header.offsetHeight || 0))) || (top + eventTarget.offsetHeight) > (documentScrollElement.scrollTop + documentHeight)) {
                        window.scrollTo(0, top - (documentHeight / 2) + (eventTarget.offsetHeight / 2));
                    }*/
                });

                var visibilityChangeHandler = function visibilityChangeHandlerFn(event) {
                    if (this[vendorPrefix ? vendorPrefix + 'Hidden' : 'hidden']) {
                        visibleEventHandle = 'hidden';
                        $rootScope.$emit('windowHide', event);
                    } else {
                        $rootScope.$emit('windowShow', event);
                        visibleEventHandle = 'show';
                    }
                };

                // Determine if a vendor prefix is required to utilize the Page Visibility API
                if ('hidden' in $document) {
                    vendorPrefix = '';
                } else {
                    angular.forEach(['moz', 'webkit', 'ms'], function (prefix) {
                        if ((prefix + 'Hidden') in $document[0]) {
                            vendorPrefix = prefix;
                        }
                    });
                }

                if (vendorPrefix !== undefined) {
                    $document[0].addEventListener(vendorPrefix + 'visibilitychange', visibilityChangeHandler);
                }

                if ($stateParams.version) {
                    $rootScope.$on('windowFocus', function () {
                        if (visibleEventHandle === 'show') return;

                        getLastUpdateTime();
                    });

                    $rootScope.$on('windowShow', function () {
                        getLastUpdateTime();
                    });
                }

                //////////////  *********** end visibility API *********** //////////

                $document.bind('click', function (event) {
                    $rootScope.$emit('document.click', event);
                });

                $document.bind('keydown', function (event) {
                    $rootScope.$emit('document.keydown', event);
                });

                $rootScope.scrollTop = 0;
                $document.bind('scroll', function (event) {
                    $rootScope.scrollTop = util.getDocumentScrolledElement(event).scrollTop;
                    $rootScope.$emit('document.scroll', event);
                });

                $rootScope.goBack = function () {
                    window.history.go(-1);
                };

                var searchParams = $location.search();
                if (searchParams && searchParams.clearStorage) {
                    util.clearStorage();
                }

                SpRecipeService.initRecipeListener();

                var _previousMessage = '',
                    _TOAST_TIMEOUT = 8000;

                $rootScope.$on('spApi.error', function (event, data) {
                    if ((data.apiOptions || {}).withoutToast || (data.response || {}).withoutToast) return;

                    if (data.response && data.response.error && data.response.error === 'The delivery time is no longer available') {
                        var _t = $filter('translate');
                        util.showCommonDialog({
                            disableClosing: true,
                            title: _t('Time slot unavailable'),
                            content: _t("We're sorry, this time slot is no longer available. Please select a different time"),
                            buttons: [
                                {
                                    text: _t('Return to schedule'),
                                    click: 'returnToSchedule()'
                                }
                            ],
                            controller: ['$scope', function ($dialogScope) {
                                $dialogScope.returnToSchedule = function () {
                                    dialog.hide();
                                    $state.go('^.details', {}, { reload: true });
                                };
                            }]
                        });
                    }

                    if (data.response && data.response.errors) {
                        var message = '';

                        var errors = data.response.errors;

                        if (data.response.jsonSchemaValidation) {
                            errors = [];
                            angular.forEach(data.response.errors, function(wrapperErrors) {
                                angular.forEach(wrapperErrors, function(error) {
                                    errors.push({
                                        param: error.property.substring(error.property.lastIndexOf('.')),
                                        msg: error.messages[0]
                                    });
                                });
                            });
                        }

                        angular.forEach(errors, function (error) {
                            var element = angular.element(document.querySelector('input[name="' + error.param + '"]'));
                            if (element && element.length) {
                                // TODO: error by element
                            } else {
                                message += $filter('translate')((error.param + ' - ' + error.msg).replace(/'/g, '\\\'')) + '. ';
                            }
                        });

                        if (message) {
                            if (data.apiOptions.dialog) {
                                util.showCommonDialog('{{\'' + message + '\'}}');
                            } else {
                                toast.show({
                                    timeout: 3000,
                                    content: '{{\'' + message + '\'}}'
                                });
                            }
                        }
                    } else if (data.response && data.response.error === 'migratedUser' && config.retailer.settings.isUserMigrationEnabled === 'true') {
                        util.showUserMigrationDialog($rootScope.emailForReActivation);
                    } else {
                        var error = 'No connection';

                        if (data.response) {
                            if (data.response.error && angular.isString(data.response.error)) {
                                error = '{{\'' + data.response.error.replace(/'/g, '\\\'') + '\' | translate}}';
                            } else {
                                error = JSON.stringify(data.response);
                            }
                        }

                        if (_previousMessage === message) {
                            return;
                        }
                        _previousMessage = message;
                        $timeout(function() {
                            _previousMessage = '';
                        }, _TOAST_TIMEOUT);
                        toast.show({
                            timeout: _TOAST_TIMEOUT,
                            content: error
                        });
                    }
                });

                if (/iPad/i.test(navigator.userAgent)) {
                    $rootScope.iPad = true;
                }

                $rootScope.$on('spApi.response', function (event, data) {
                    // if internet rimon block any request show dialog
                    if (data.headers('Rimon') && data.headers('Rimon').toUpperCase() === 'RWC_BLOCK') {
                        util.showCommonDialog({
                            title: '{{\'Service Provider Blocker\' | translate}}',
                            content: '{{\'Your service provider is blocking some of the website\\\'s functionality\' | translate}}.<br/>{{\'Please contact your service provider\' | translate}}.',
                            buttons: [{click: dialog.hide, text: '{{\'Close\' | translate}}'}]
                        });
                    }
                });

                $rootScope.$on('$stateChangeStart', function (evt, to, params) {
                    _stateChangeStartPosition = {
                        __scrollX: window.scrollX,
                        __scrollY: window.scrollY
                    };

                    // this is needed for redirecting from one state too another one
                    if (to.redirectTo) {
                        evt.preventDefault();
                        $state.go(to.redirectTo, params);
                    }
                });

                $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                    // Try scrolling to the previous scroll position on $stateChangeSuccess
                    var state = history.state;
                    if (!state || isNaN(history.state.__scrollX) || isNaN(history.state.__scrollY)) {
                        return;
                    }
                    var timeout = toState.name === 'app.categoryProducts' ? 4000 : 0;

                    $timeout(function () {
                        if (fromState && toState.name !== 'app.home') {
                            document.querySelector('section.view').focus();
                        }

                        var body = document.body,
                            html = document.documentElement,
                            documentWidth = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth),
                            documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

                        if (documentWidth - html.clientWidth - state.__scrollX >= 0 && documentHeight - html.clientHeight - state.__scrollY >= 0) {
                            window.scrollTo(state.__scrollX, state.__scrollY);
                        }

                        AdobeAnalytics.newPageEvent();
                    }, timeout);
                });

                // to debug errors in ui router resolve
                $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
                    if (!(error instanceof Error)) {
                        error = new Error(JSON.stringify(error));
                        error.originalError = error;
                    }

                    throw error;
                });

                var migration = (localStorageService.getItem('migration') && JSON.parse(localStorageService.getItem('migration'))) || {};
                if (!user.session.userId && config.retailer && config.retailer.settings.isUserMigrationEnabled === 'true') {
                    if (!migration.hide && (!migration.initialTime || (new Date(migration.initialTime).getTime() + 300000) < new Date().getTime())) {
                        $timeout(function () {
                            util.showUserMigrationDialog();
                        }, 2000);
                    }
                }

                config.retailer.settings.useDeliveryAddressAsBilling = config.retailer.settings.useDeliveryAddressAsBilling === 'true';
                config.retailer.settings.enableDefaultCountry = config.retailer.settings.enableDefaultCountry === 'true';
                config.retailer.settings.autocompleteAddressField =  config.retailer.settings.autocompleteAddressField === 'true';
                config.retailer.settings.showPriceWithoutBottleDeposit =  config.retailer.settings.showPriceWithoutBottleDeposit === 'true';
                config.retailer.settings.showCouponsSpecials = config.retailer.settings.showCouponsSpecials === 'true';

                if (config.retailer.settings.isNewPromotionDesignEnabled === 'true') {
                    $rootScope.$on('$locationChangeStart', _hidePromotionTooltip);
                    $rootScope.$on('$stateChangeStart', _hidePromotionTooltip);
                    angular.element(window).bind('resize', _hidePromotionTooltip);

                    function _hidePromotionTooltip() {
                        $rootScope.promotionTooltip = null;
                    }
                }

                try {
                    if (config.retailer.settings.specificAddressDetails && typeof config.retailer.settings.specificAddressDetails === 'string') {
                        config.retailer.settings.specificAddressDetails = JSON.parse(config.retailer.settings.specificAddressDetails);
                    }

                    if (config.retailer.settings.creditCardTextReplacement && typeof config.retailer.settings.creditCardTextReplacement === 'string') {
                        config.retailer.settings.creditCardTextReplacement = JSON.parse(config.retailer.settings.creditCardTextReplacement);
                    }
                    if (config.retailer.settings.specialNameReplacement && typeof config.retailer.settings.specialNameReplacement === 'string') {
                        config.retailer.settings.specialNameReplacement = JSON.parse(config.retailer.settings.specialNameReplacement);
                    }

                    if (config.retailer.settings.joinConnectTextReplacement && typeof config.retailer.settings.joinConnectTextReplacement === 'string') {
                        config.retailer.settings.joinConnectTextReplacement = JSON.parse(config.retailer.settings.joinConnectTextReplacement);
                    }

                    if (config.retailer.settings.cvvTextReplacement && typeof config.retailer.settings.cvvTextReplacement === 'string') {
                        config.retailer.settings.cvvTextReplacement = JSON.parse(config.retailer.settings.cvvTextReplacement);
                    }

                    if (config.retailer.settings.cvvDescriptionTextReplacement && typeof config.retailer.settings.cvvDescriptionTextReplacement === 'string') {
                        config.retailer.settings.cvvDescriptionTextReplacement = JSON.parse(config.retailer.settings.cvvDescriptionTextReplacement);
                    }

                    if (config.retailer.settings.disclaimerOnProductPages && typeof config.retailer.settings.disclaimerOnProductPages === 'string') {
                        config.retailer.settings.disclaimerOnProductPages = JSON.parse(config.retailer.settings.disclaimerOnProductPages);
                    }

                    if (config.retailer.settings.disclaimerOnCartPages && typeof config.retailer.settings.disclaimerOnCartPages === 'string') {
                        config.retailer.settings.disclaimerOnCartPages = JSON.parse(config.retailer.settings.disclaimerOnCartPages);
                    }

                    if (config.retailer.settings.agProductsOriginLegalText && typeof config.retailer.settings.agProductsOriginLegalText === 'string') {
                        config.retailer.settings.agProductsOriginLegalText = JSON.parse(config.retailer.settings.agProductsOriginLegalText);
                    }

                    if (config.retailer.settings.isAgProductsOriginFromPOSActive && typeof config.retailer.settings.isAgProductsOriginFromPOSActive === 'string') {
                        config.retailer.settings.isAgProductsOriginFromPOSActive = JSON.parse(config.retailer.settings.isAgProductsOriginFromPOSActive);
                    }

                    if (config.retailer.settings.isAgProductsOriginLegalTextActive && typeof config.retailer.settings.isAgProductsOriginLegalTextActive === 'string') {
                        config.retailer.settings.isAgProductsOriginLegalTextActive = JSON.parse(config.retailer.settings.isAgProductsOriginLegalTextActive);
                    }
                } catch (e) {
                    console.log(e);
                }

            }]);


    function _postMessage(data, allowedDomain) {
        // has port only when debugging
        if (window.location.port) {
            window.parent.postMessage(data, window.location.origin);
        } else {
            window.parent.postMessage(data, 'https://' + allowedDomain);
        }
    }

    // *** Scroll Restoration ***

    var originalPushState = window.history.pushState,
        originalReplaceState = window.history.replaceState;

    // Store current scroll position in current state when navigating away.
    window.history.pushState = function () {
        var newStateOfCurrentPage = angular.extend({}, window.history.state, _stateChangeStartPosition);
        originalReplaceState.call(window.history, newStateOfCurrentPage, '');

        originalPushState.apply(window.history, arguments);
    };

    // Make sure we don't throw away scroll position when calling "replaceState".
    window.history.replaceState = function () {
        arguments[0] = angular.extend({}, arguments[0], {
            __scrollX: window.history.state && window.history.state.__scrollX,
            __scrollY: window.history.state && window.history.state.__scrollY
        });
        originalReplaceState.apply(window.history, arguments);
    };

    // *** Theme CSS ***

    function buildCss(themeConfig) {
        window.sp.themeConfig = angular.extend(window.sp.defaultThemeConfig, themeConfig);

        window.spBuildCss(window.sp.themeCss, window.sp.themeConfig, document.getElementById('theme_css'));
    }

    try {
        var themConfigUrl = window.sp.frontendData.retailer.settings.themeConfig;

        if (themConfigUrl) {
            themConfigUrl += themConfigUrl.indexOf('.js') === -1 ? '.js' : '';
            themConfigUrl += '?_=' + Math.random();

            var themeCssScript = document.createElement('script');

            themeCssScript.setAttribute('src', themConfigUrl);
            themeCssScript.onload = function () {
                buildCss(window.define.data);
                delete window.define;
            };

            document.head.appendChild(themeCssScript);
        } else {
            buildCss();
        }
    } catch (e) {
        buildCss();
    }

    // *** Require JS (legacy support) ***
    window.define = function (arr, fn) {
        if (typeof fn === 'function') {
            window.define.data = fn();
        }

        if (window.location.hostname.indexOf('localhost') > -1) {
            console.info(_tag, 'define function of requireJS (legacy support) was called.');
        }
    };
})(window, angular);
